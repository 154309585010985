.custom-input-file {
    background-color: #941B80;
    color: #fff;
    cursor: pointer;
    font-size: 18px;
    border-radius: 10px;
    font-weight: bold;
    margin: 0 auto 0;
    min-height: 15px;
    overflow: hidden;
    padding: 10px;
    position: relative;
    text-align: center;
    width: 400px;
  }
  
  .custom-input-file .input-file {
   border: 10000px solid transparent;
   cursor: pointer;
   font-size: 10000px;
   margin: 0;
   opacity: 0;
   outline: 0 none;
   padding: 0;
   position: absolute;
   right: -1000px;
   top: -1000px;
  }