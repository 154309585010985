.App{
    background: rgb(56,2,43);
    background: linear-gradient(180deg,
     rgba(238,69,64,1) 0%, 
     rgba(150,44,85,1) 35%, 
     rgb(128, 19, 54,1) 70%,
     rgba(45,20,44,1) 100%);
}


